<template>
  <div class="kraf-analytics-page">
    <v-row justify="center">
      <v-col md="6">
        <!-- FEATURE ANALYTICS -->
        <v-slide-y-transition mode="out-in">
          <div v-if="showFeaturesAnalytics">
            <!-- Books -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4" :to="{ name: 'KrafBookAnalyticsDetails' }">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="books"
                  width="50px"
                  height="40px"
                />
                <h3>Books</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="3" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_book_topics" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Published</p>
                </v-col>

                <v-col cols="3" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_published_book_topics" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Published</p>
                </v-col>

                <v-col cols="3" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_book_topics" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Topics</p>
                </v-col>
              </v-row>
            </v-card>

             <!-- Activity Sheets -->
            <v-card outlined class="mx-2 my-3 px-2 py-3 pb-4" :to="{ name: 'KrafActivitySheetsAnalyticsDetails' }">
              <div class="d-flex align-center">
                <feature-svg
                  class="mr-3"
                  name="activitySheets"
                  width="50px"
                  height="40px"
                />
                <h3>Activity Sheets</h3>
              </div>
              <v-divider class="my-2"></v-divider>

              <v-row justify="center" dense class="mx-1 mt-2">
                <v-col cols="3" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Today</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.todays_activity_topics" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Published</p>
                </v-col>

                <v-col cols="3" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_published_activity_topics" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Published</p>
                </v-col>

                <v-col cols="3" class="text-center mx-3">
                  <p class="body-2 font-weight-bold ma-0">Total</p>
                  <h1 class="primary--text">
                    <animated-counter :number="featuresAnalytics.total_activity_topics" />
                  </h1>
                  <p class="body-2 font-weight-bold ma-0">Topics</p>
                </v-col>
              </v-row>
            </v-card>
            </div>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Mixins from "@utils/mixins";
import FeatureSvg from "@assets/images/FeatureSvg.vue";
import AnimatedCounter from "@components/ui/AnimatedCounter";

export default {
  name: "Analytics",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    FeatureSvg,
    AnimatedCounter,
  },

  data() {
    return {
      featuresAnalytics: {},
      showFeaturesAnalytics: false,
    };
  },

  methods: {
    async getFeaturesAnalytics() {
      var url = this.endpoints.krafAnalyticsViewSet;
      console.log(url);
      this.featuresAnalytics = await this.api.call(this.essentials, url);
    },

    async showDownloadsMobileApp() {
      this.showDownloads = (await this.Helper.getDevicePlatform()) != "web";
    },

    async onRefresh() {
      this.getFeaturesAnalytics();
    },
  },

  async created() {
    setTimeout(() => (this.showFeaturesAnalytics = true), 300);
    this.onRefresh();
    this.showDownloadsMobileApp();
  },
};
</script>

<style>
</style>